/* ServiceOrderTable.css */


.service-order-table {
  width: 100%;
  border-collapse: collapse;
}

.service-order-table th,
.service-order-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.service-order-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.header-cell {
  width: 100%;
  height: 100%;
  line-height: normal;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
}

.pmwa-column {
  width: 60px;
  text-align: center;
}

.pmwa-cell {
  width: 60px;
  text-align: center;
}

.pmwa-icon {
  cursor: pointer;
}

.service-order-row:hover {
  background-color: #f1f1f1;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  padding: 1.5rem;
  width: 600px;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.error-message {
  color: red;
}

.note-item {
  margin: 0.3rem 0;
}

.awf-searchContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
}

.awf-searchInput.service-order-search {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.awf-button {
  padding: 8px 16px;
  margin: 0 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
}

.awf-button:hover {
  background-color: #0056b3;
}

/* Spinner Animation */
.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% { transform: rotate(360deg); }
}

.has-lvm-update {
  background-color: #ffe6e6 !important;
}

/* Optional: a class for the note cards in the modal. We'll name it .note-card */
.note-card {
  margin-bottom: 1.5rem;
  padding: 1rem;
  border: 2px solid #ddd;
  border-radius: 5px;
}

/* .pulse toggles a subtle outer glow */
.note-card.pulse {
  animation: borderPulse 2s infinite ease-in-out;
}

@keyframes borderPulse {
  0%, 100% {
    border-color: #fdcccc;
  }
  50% {
    border-color: #fa9d9d;
  }
}

/* Style for Department Filter Panel */
.dept-filter-panel {
  margin-top: 0.5rem;
}

/* Ensure each department filter item is on its own line */
.dept-filter-item {
  margin-bottom: 0.5rem;
}

/* Optional: Adjust the appearance of the toggle button */
.dept-filter-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Tooltip wrapper styling */
.tooltip-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Tooltip content styling */
.tooltip-wrapper .tooltip-content {
  visibility: hidden;
  width: 200px; /* Adjust width as needed */
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 100;
  bottom: 125%;  /* Position it above the text */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.85rem;
  white-space: pre-wrap;
}

/* Tooltip arrow */
.tooltip-wrapper .tooltip-content::after {
  content: "";
  position: absolute;
  top: 100%; /* At the bottom of tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

/* Show tooltip on hover */
.tooltip-wrapper:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}
