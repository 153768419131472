/* Existing styles */

/* New Bullseye Button */
.bullseye-button {
  background-color: #ff0000 !important; /* Red background */
  color: #ffffff;                       /* White bullseye icon */
  border: none;
  border-radius: 4px;                   /* Slightly rounded corners */
  width: 40px;                          /* Button width */
  height: 40px;                         /* Button height */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 8px;                     /* Space between buttons */
  padding: 0;                           /* Remove default padding */
}

.bullseye-button:hover {
  background-color: #cc0000; /* Darker red on hover */
}

.bullseye-button:disabled {
  background-color: #ffcccc; /* Light red when disabled */
  cursor: not-allowed;
}

.bullseye-icon {
  /* Additional styling if needed */
  /* Example: */
  transform: scale(3); /* Scales the icon 1.5 times its original size */
}

