/* Container for the entire dispatcher board */
.dispatcher-board {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

/* Filters section */
.filters {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.filters-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.filters-left {
  display: flex;
  align-items: flex-start;
}

.filters-right {
  display: flex;
  align-items: center;
}

.filter-group {
  display: flex;
  margin-left: 20px;
}

.filter-item {
  margin-right: 20px;
}

.filter-item label {
  font-weight: bold;
  display: block;
}

.filter-options {
  max-height: 200px;
  overflow-y: auto;
  padding: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

.filter-options div {
  margin-bottom: 5px;
}

/* Adjustments for input fields */
.filter-options input {
  margin-right: 5px;
}

/* Main container for the board */
.board-container {
  display: flex;
}

/* Unassigned column styling */
.unassigned-column {
  width: 200px;
  margin-right: 20px;
}

.unassigned-column h3 {
  text-align: center;
}

.appointment-droppable {
  background-color: #f8f8f8;
  min-height: 600px; /* Adjust height as needed */
  padding: 10px;
  border: 1px solid #ccc;
  overflow-y: auto; /* Scroll if content exceeds the height */
}

.appointment-droppable.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
}

/* Technicians board styling */
.technicians-board {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* Each technician row */
.technician-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/* Technician name card */
.technician-name-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  min-width: 100px;
  height: 140px; /* Match the height of the appointment container */
  margin-right: 10px;
  background-color: #e9ecef; /* Light gray background */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden; /* Hide overflow if name is too long */
}

.technician-name-text {
  text-align: center;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #333;

  /* Allow text to wrap */
  white-space: normal;
  word-break: break-word;
}

@media (max-width: 600px) {
  .technician-name-text {
    font-size: 14px; /* Reduce font size on small screens */
  }
}

/* Technician appointments container */
.technician-appointments-container {
  display: flex;
  flex-grow: 1;
  height: 140px;
  background-color: #f0f0f0;
  padding: 80px;
  border: 1px solid #ccc;
  align-items: center;
  box-sizing: border-box;
  overflow: visible;
}

/* Unassigned row appointments container */
.unassigned-row .technician-appointments-container {
  flex-grow: 1;
  width: 100%;
  height: auto;
  align-items: flex-start;
}

/* Appointment card styling */
.appointment-card {
  height: 72px; /* Adjust as needed; 60px + 20% = 72px */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px; /* small spacing between cards */
  background-color: white;
  border: 1px solid #ccc;
  min-width: 100px;
  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 0;
  border-radius: 4px;
}

.appointment-card:last-child {
  margin-right: 0;
}

/* Status color classes */
.appointment-card.light-blue {
  background-color: #AEC6CF; /* Light Blue */
}

.appointment-card.blue {
  background-color: #87CEEB; /* Blue */
}

.appointment-card.teal {
  background-color: #B2DFDB; /* Teal */
}

.appointment-card.light-green {
  background-color: #C5E1A5; /* Light Green */
}


/* Pastel color classes for appointment statuses */
.appointment-card {
  /* Common styles for all appointment cards */
  color: #333;
  padding: 10px;
  margin-bottom: 0;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.appointment-card.pastel-blue {
  background-color: #cce5ff; /* Light blue for 'Assigned' */
}

.appointment-card.pastel-light-blue {
  background-color: #b3d7ff; /* Slightly darker blue for 'Dispatched' */
}

.appointment-card.pastel-teal {
  background-color: #b2f0e6; /* Teal for 'Depart' */
}

.appointment-card.pastel-light-green {
  background-color: #d4edda; /* Light green for 'Arrive' */
}

.appointment-card.pastel-green {
  background-color: #c3e6cb; /* Green for 'Complete' */
}

.appointment-card.pastel-yellow {
  background-color: #fff3cd; /* Yellow for 'On Hold' */
}

.appointment-card.pastel-gray {
  background-color: #e2e3e5; /* Gray for 'Canceled' or unknown statuses */
}

/* Style for the appointment content */
.appointment-content {
  font-weight: bold;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;    /* Optional: Hide overflow if text is too long */
  text-overflow: ellipsis; /* Optional: Add ellipsis (...) for overflowing text */
}

/* Style for the status icon */
.status-icon {
  margin-left: 8px;
}

/* Style for the Unassigned row */
.unassigned-row {
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 5px;
  margin-bottom: 20px; /* Space between Unassigned and technician rows */
  padding: 10px;
  background-color: #f8f9fa; /* Light background for contrast */
  margin-top: 150px; /* Adds 100px of space above the Unassigned row */
}

/* Remove the label styling as it's no longer needed */
.unassigned-label {
  padding: 10px;
  background-color: #f8f9fa; /* Light gray background */
 
  width: 175px;
}

.unassigned-text {
  font-weight: bold;
  font-size: 1.1em;
}



/* Appointment row inside Droppable */
.appointment-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  overflow: visible;
  width: 100%;
  gap: 10px;
}

/* Unassigned row adjustments */
.unassigned-row .technician-name-card {
  display: none; /* Hide the name card for unassigned row */
}

.filter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.filter-header > div {
  display: flex;
  align-items: center;
}

.filter-header label {
  font-weight: bold;
  margin-right: 5px;
}

.filter-search {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  box-sizing: border-box;
}

/* Reset button styling */
.reset-button {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

.appointment-card .tooltip {
  position: absolute;
  bottom: 100%; /* position above the card */
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px; /* small gap between card and tooltip */
  background-color: rgba(255, 255, 255, 0.95);
  color: #333;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  z-index: 10;
  white-space: normal;
  width: 600px; /* Increase or adjust as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Show the tooltip when hovering */
.appointment-card:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

/* Adjust text styles within the tooltip */
.appointment-card .tooltip div {
  margin-bottom: 5px;
}

/* Ensure the appointment-content-wrapper uses flex to align its children */
.appointment-content-wrapper {
  position: relative; /* Keep this for tooltip positioning */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 6px 8px;
  box-sizing: border-box;
}

/* Put the icon on the top left, number on the same line */
.appointment-header {
  display: flex;
  align-items: center;
  width: 100%;
}

/* Keep the icon in the top-left corner */
.appointment-header .status-icon {
  margin-right: 6px;
}

/* The appointment number sits next to the icon on the same line */
.appointment-number {
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
}

/* Place the customer's name on the next line, truncated if too long */
.customer-name {
  margin-top: 4px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.95rem;
}

/* Tooltip styles */
.tooltip {
  position: absolute; /* Position relative to .appointment-card */
  z-index: 1001; /* Ensure it appears above the nav menu */
  
  /* Optional: Enhance tooltip appearance */
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
  white-space: nowrap;
  
  /* Optional: Add a slight transition for smooth appearance */
  transition: opacity 0.3s ease;
  opacity: 0;
  
  /* Position the tooltip above the card */
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  
  /* Ensure the tooltip is visible */
  pointer-events: none;
}

/* Show tooltip on hover */
.appointment-card:hover .tooltip {
  opacity: 1;
}

/* Provide some spacing at the bottom for the unassigned row */
.technician-row.unassigned-row {
  margin-bottom: 10px; /* already inlined, but you can move it here for consistency */
}

