/* AppointmentWorkFlow Component Styles */

/* Content Spacing with Increased Specificity */
.AwF-AppointmentWorkFlow .awf-content-spacing {
  padding-left: 2px;
  padding-right: 2px;
  min-height: calc(100vh - 267px);
  margin-top: 50px;
}

/* Media query for mobile devices with increased specificity */
@media (max-width: 768px) {
  /* More specific selectors to override components.css */
  .AwF-AppointmentWorkFlow .awf-content-spacing {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }

  /* Optional: Adjust padding or other properties for mobile */
  .AwF-AppointmentWorkFlow .awf-card {
    padding: 1rem; /* Reduce padding on mobile */
  }

  .AwF-AppointmentWorkFlow .awf-cardHeader {
    padding: 1rem;
    margin-bottom: 0; /* Remove margin-bottom to eliminate space */
  }

  /* Adjust card content padding */
  .AwF-AppointmentWorkFlow .awf-cardContent {
    padding: 1rem;
  }

  /* Adjust font sizes for better readability */
  .AwF-AppointmentWorkFlow .awf-cardTitle {
    font-size: 1.5rem;
  }

  /* Ensure tab buttons adjust properly */
  .AwF-AppointmentWorkFlow .awf-tabButton {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  /* Adjust appointment item padding and margins */
  .AwF-AppointmentWorkFlow .awf-appointmentItem {
    padding: 0.8rem;
  }

  /* Adjust other elements as needed */

  /* Center Menu Items Vertically on Mobile */
  .pro-sidebar .menu {
    justify-content: center; /* Center vertically */
  }
}

/* Media query for mobile devices to remove side margins */
@media (max-width: 768px) {
  .awf-content-spacing {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

/* Card */
.awf-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
}

.awf-cardHeader {
  background: #0066cc;
  color: white;
  padding: 2rem 1rem;
  text-align: left;
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.awf-cardTitle {
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
}

/* Card Content */
.awf-cardContent {
  padding-left: 2px;
  padding-right: 2px;
  width: 100%;
  
  box-sizing: border-box;
  margin: 0 auto;
}

/* Tabs */
.awf-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  border-bottom: 1px solid #e0e0e0;
  flex-wrap: wrap;
}

.awf-tabButton {
  background: none;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: #666;
  transition: color 0.3s, border-bottom 0.3s;
}

.awf-tabButton:hover,
.awf-activeTab {
  color: #0066cc;
  border-bottom: 3px solid #0066cc;
}

/* Back Button */
.awf-backButton {
  background: #f8f9fa;
  border: 1px solid #6c757d;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  color: #6c757d;
  border-radius: 4px;
  margin-left: auto;
  transition: background-color 0.3s, color 0.3s;
}

.awf-backButton:hover {
  background: #6c757d;
  color: white;
}

/* Tab Content */
.awf-tabContent {
  max-width: 800px;
  margin: 0 auto;
}

/* Current Appointments Content */
.awf-currentAppointmentsContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.awf-appointmentsTitle {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.awf-appointmentsList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.awf-appointmentItem {
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.awf-appointmentItem:hover {
  background: #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.awf-appointmentNumber,
.awf-customerName,
.awf-issueDescription {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #555;
}

.awf-appointmentNumber strong,
.awf-customerName strong,
.awf-issueDescription strong {
  color: #333;
}

/* Details Content */
.awf-detailsContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.awf-infoItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  background: #f0f8ff;
  padding: 1rem;
  border-radius: 8px;
}

.awf-infoLabel {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.awf-infoValue {
  font-size: 1.2rem;
  color: #555;
}

/* Status Content */
.awf-statusContent {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center text within the container */
  margin-top: 20px; /* Add some spacing above the status section */
}

.awf-statusTitle {
  font-size: 1.5rem;
  margin-bottom: 1rem; /* Add spacing below the title */
  color: #333;
}

.awf-statusButtons {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin-top: 10px; /* Add some spacing above the button */
}

.awf-statusButton {
  padding: 15px 30px; /* Increase padding for larger button size */
  font-size: 1.2rem; /* Increase font size for better readability */
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.awf-statusButton:hover {
  background-color: #0056b3;
}

.awf-statusButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .awf-statusButton {
    padding: 20px 40px; /* Further increase padding for mobile */
    font-size: 1.4rem; /* Further increase font size for mobile */
  }
}

/* Actions Content */
.awf-actionsContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.awf-formGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.awf-formGroup label {
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #333;
  font-size: 1rem;
}

.awf-formGroup input,
.awf-formGroup textarea {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
  width: 100%;
  max-width: 500px;
}

.awf-textarea {
  min-height: 150px;
  resize: vertical;
}

/* Upload Buttons */
.awf-uploadButtons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.awf-uploadButton {
  background: #f0f0f0;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  color: #666;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.awf-uploadButton:hover {
  background: #e0e0e0;
}

.awf-icon {
  margin-right: 0.5rem;
  font-size: 1.25rem;
}

/* Signature Box */
.awf-signatureBox {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  color: #aaa;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.awf-signatureImage {
  max-width: 100%;
  height: auto;
}

/* Save Button */
.awf-saveButton {
  background: #28a745;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  transition: background-color 0.3s;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
}

.awf-saveButton:hover {
  background: #218838;
}

/* Responsive Design */
@media (min-width: 768px) {
  .awf-tabs {
    justify-content: flex-start;
  }

  .awf-tabButton {
    padding: 1rem 1.5rem;
  }

  .awf-detailsContent {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 2rem;
  }

  .awf-infoItem {
    width: calc(50% - 2rem);
    align-items: flex-start;
    text-align: left;
  }

  .awf-statusButtons {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .awf-actionsContent {
    align-items: flex-start;
  }

  .awf-formGroup {
    align-items: flex-start;
  }

  .awf-uploadButtons {
    justify-content: flex-start;
  }

  .awf-currentAppointmentsContent {
    gap: 2rem;
  }

  .awf-appointmentItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .awf-appointmentNumber,
  .awf-customerName,
  .awf-issueDescription {
    margin-bottom: 0;
    font-size: 1rem;
  }

  .awf-appointmentItem div {
    flex: 1;
  }

  .awf-appointmentNumber {
    flex: 1;
  }

  .awf-customerName {
    flex: 2;
  }

  .awf-issueDescription {
    flex: 3;
  }
}

@media (min-width: 1024px) {
  .awf-cardContent {
    padding: 2rem 3rem;
  }

  .awf-tabContent {
    max-width: none;
    margin: 0;
  }

  .awf-saveButton {
    max-width: none;
  }
}

/* ServiceOrderLookup Component Styles */

/* Controls */
.awf-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.awf-inputGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.awf-label {
  font-weight: bold;
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.awf-inputWrapper {
  display: flex;
  align-items: center;
}

.awf-prefix {
  background: #f0f0f0;
  border: 1px solid #ccc;
  padding: 0.75rem 1rem;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  font-size: 1rem;
  color: #333;
}

.awf-input {
  padding: 0.75rem 1rem;
  border: 1px solid #ccc;
  border-left: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 1rem;
  color: #333;
  width: 200px;
}

.awf-button {
  background: #0066cc;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  color: white;
  transition: background-color 0.3s;
}

.awf-button:hover {
  background: #005bb5;
}

.awf-divider {
  margin: 1rem 0;
  font-size: 1rem;
  color: #666;
}

.awf-error {
  color: red;
  margin-top: 1rem;
}

.awf-loading {
  margin-top: 1rem;
  font-size: 1rem;
  color: #333;
}

.awf-noResults {
  margin-top: 1rem;
  font-size: 1rem;
  color: #333;
}

/* Results */
.awf-results {
  margin-top: 2rem;
}

.awf-resultsTitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
}

.awf-resultsList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.awf-resultItem {
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.awf-resultItem div {
  margin-bottom: 0.5rem;
}

.awf-resultItem strong {
  color: #333;
}

.awf-paginationNote {
  margin-top: 1rem;
  font-size: 1rem;
  color: #666;
  text-align: center;
}

/* Responsive Design */
@media (min-width: 768px) {
  .awf-controls {
    flex-direction: row;
    align-items: flex-end;
    gap: 1rem;
    justify-content: center;
  }

  .awf-inputGroup {
    align-items: flex-start;
  }

  .awf-divider {
    display: none;
  }
}

/* Existing styles... */

/* Add 50 pixels of space at the top */
.awf-content-spacing {

  min-height: calc(100vh - 267px);
  margin-top: 50px;
}

/* Loading Spinner */
.awf-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.awf-spinner {
  width: 40px;
  height: 40px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #0066cc; /* Blue */
  border-radius: 50%;
  animation: awf-spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes awf-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Adjusted margins for better spacing */
.awf-cardTitle {
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
}

.awf-cardHeader {
  background: #0066cc;
  color: white;
  padding: 2rem 1rem;
  text-align: left;
  margin-bottom: 1rem;
}

.service-order-details {
  margin-left: 0;
  margin-top: 10px;
  padding-left: 1rem;
}

.service-order-details p {
  margin-bottom: 0;
}

/* Search Container */
.awf-searchContainer {
  margin-bottom: 1rem;
  text-align: center;
}

.awf-searchInput {
  padding: 0.5rem 1rem;
  width: 80%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
}

/* Table Styles */
.awf-table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  margin-bottom: 2rem;
  margin-left: 2px;
  margin-right: 2px;
  padding-left: 2px;
  padding-right: 2px;
}

.awf-table th,
.awf-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.awf-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.awf-table tr:hover {
  background-color: #f1f1f1;
}

.awf-table th::after {
  content: attr(data-sort-symbol);
  position: absolute;
  right: 1rem;
}

.awf-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.awf-table tbody tr:hover {
  background-color: #e0e0e0;
}

/* AppointmentScreen Component Styles */

/* Buttons */
.awf-addButton {
  background: #28a745;
  margin-top: 1rem;
}

.awf-addButton:hover {
  background: #218838;
}

.awf-cancelButton {
  background: #dc3545;
}

.awf-cancelButton:hover {
  background: #c82333;
}

/* Loading */
.awf-loading {
  margin-top: 1rem;
  font-size: 1rem;
  color: #333;
  text-align: center;
}

/* No Results */
.awf-noResults {
  margin-top: 1rem;
  font-size: 1rem;
  color: #333;
  text-align: center;
}

/* Technician List */
.awf-technician-list {
  list-style: none;
  padding: 0;
  max-height: 300px;
  overflow-y: auto;
}

.awf-technician-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.awf-technician-item:hover {
  background-color: #f0f0f0;
}

.awf-technician-item.selected {
  background-color: #d0e9ff;
}

/* src/components/AppointmentScreen.css */

.awf-technician-list {
  list-style-type: none;
  padding: 0;
}

.awf-technician-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.awf-technician-item:hover {
  background-color: #f0f0f0;
}

.awf-technician-item.selected {
  background-color: #d0e7ff;
}

/* appointments.css */

/* Container for the details content */
.awf-detailsContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Wrapper for rows */
.awf-row {
  display: flex;
  gap: 32px; /* Adjust spacing between items as needed */
}

/* Individual information items */
.awf-infoItem {
  display: flex;
  flex-direction: column;
  flex: 1; /* Allows items to grow equally */
}

/* Label styling */
.awf-infoLabel {
  font-weight: bold;
  margin-bottom: 4px;
}

/* Value styling */
.awf-infoValue {
  font-size: 14px;
  color: #333;
}

/* Expanded Description Item */
.awf-descriptionItem {
  display: flex;
  flex-direction: column;
}

/* Ensuring Description takes more space */
.awf-descriptionItem .awf-infoValue {
  white-space: pre-wrap; /* Allows text to wrap */
  word-wrap: break-word; /* Breaks long words */
  /* Optionally, adjust the width or flex properties */
}

@media (max-width: 768px) {
  .awf-row {
    flex-direction: column;
    gap: 16px;
  }

  .awf-descriptionItem {
    width: 100%;
  }

  .awf-detailsContent.compact {
    padding: 10px;
    /* Add any additional styling to make it occupy less space */
  }

  .awf-breadcrumbs {
    font-size: 14px;
    margin-bottom: 10px;
    padding-left: 20px; /* Add padding to the left */
    display: flex;
    align-items: center;
  }

  .awf-breadcrumb-link {
    color: #007bff;
    text-decoration: none;
  }

  .awf-breadcrumb-link:hover {
    text-decoration: underline;
  }

  .awf-breadcrumb-current {
    color: #6c757d;
  }

  .awf-breadcrumb-separator {
    margin: 0 5px;
    color: #6c757d;
  }

  .awf-statusSection {
    margin-top: 20px;
  }

  .awf-statusButtons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
  }

  .awf-actionsSection {
    margin-top: 30px;
  }

  /* Additional mobile-specific styles can be added here */
}

/* ... existing styles continue ... */

/* Status Button Styling */
.awf-statusButton {
  padding: 10px 20px;
  margin: 5px;
  background-color: #007bff; /* Bootstrap primary blue */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.awf-statusButton:hover:not(:disabled) {
  background-color: #0056b3;
}

.awf-statusButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.awf-activeStatus {
  background-color: #28a745; /* Bootstrap green for active status */
}

.awf-success {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
}

.awf-error {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
}

/* ... existing styles ... */

/* Dispatch Button */
.awf-dispatchButton {
  width: 110px; /* Ensure the width is consistent */
  text-align: center; /* Center the text */
  display: inline-block; /* Ensure the button behaves like a block element */
  padding: 0.75rem 0; /* Adjust padding to center text vertically */
  background-color: #007bff; /* Default color for 'Dispatch' */
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.awf-dispatchButton:hover {
  background-color: #005bb5; /* Hover color */
}

.awf-dispatchButton:disabled {
  background-color: #6c757d; /* Disabled color */
  cursor: not-allowed;
}

/* ... existing styles ... */

/* appointments.css */

.awf-detailsContent.compact {
  padding: 10px;
  /* Add any additional styling to make it occupy less space */
}

.awf-breadcrumbs {
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 20px; /* Add padding to the left */
  display: flex;
  align-items: center;
}

.awf-breadcrumb-link {
  color: #007bff;
  text-decoration: none;
}

.awf-breadcrumb-link:hover {
  text-decoration: underline;
}

.awf-breadcrumb-current {
  color: #6c757d;
}

.awf-breadcrumb-separator {
  margin: 0 5px;
  color: #6c757d;
}

.awf-statusSection {
  margin-top: 20px;
}

.awf-statusButtons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.awf-statusButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.awf-statusButton:hover {
  background-color: #0056b3;
}

.awf-actionsSection {
  margin-top: 30px;
}

/* Add any additional styling as needed */

/* Ensure other mobile-specific styles are optimized */
@media (max-width: 768px) {
  /* Reduce padding in other components if necessary */
 
  /* Adjust font sizes for better readability */
  .awf-cardTitle {
    font-size: 1.5rem;
  }

  /* Adjust other elements as needed */
}

/* Remove margin-bottom from card header */
.AwF-AppointmentWorkFlow .awf-cardHeader {
  margin-bottom: 0; /* Eliminates space below header */
  padding: 1rem; /* Adjusted padding for mobile */
}

/* Optional: Additional adjustments for card components */
.AwF-AppointmentWorkFlow .awf-cardContent {
  padding: 1rem; /* Reduce padding on mobile */
}

/* Center the awf-infoBox horizontally within the card and left-justify its content */
.awf-infoBox {
  margin: 0 auto; /* Centers the box horizontally */
  width: 100%;
  max-width: 600px; /* Adjust the max-width as needed */
  background-color: #f0f8ff; /* Light blue background for the info box */
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left; /* Ensure text is left-aligned */
}

/* Style the awf-descriptionBox and position it below awf-infoBox */
.awf-descriptionBox {
  margin: 1.5rem auto 0; /* Adds top margin to separate from awf-infoBox */
  width: 100%;
  max-width: 600px; /* Consistent with awf-infoBox */
  background-color: #e6f7ff; /* Slightly different blue for distinction */
  padding: 1.5rem;
  border: 1px solid #b3e5fc;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Stack label above description */
  gap: 0.5rem;
  text-align: left; /* Ensure text is left-aligned */
}

/* Style labels and values within awf-descriptionBox */
.awf-descriptionBox .awf-infoLabel {
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.awf-descriptionBox .awf-infoValue {
  font-size: 1rem;
  color: #333;
  white-space: pre-wrap; /* Allows text to wrap */
  word-wrap: break-word; /* Breaks long words */
}

/* Center the awf-detailsContent within the card */
.awf-detailsContent {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers child elements horizontally */
  gap: 1.5rem;
  padding: 1rem;
}

/* Ensure responsiveness for smaller screens */
@media (max-width: 768px) {
  .awf-infoBox,
  .awf-descriptionBox {
    max-width: 95%; /* Make the box wider on mobile */
    padding: 1rem; /* Reduce padding on mobile */
  }

  /* Left-justify content within awf-infoBox */
  .awf-infoBox {
    text-align: left; /* Ensure text is left-aligned on mobile */
  }

  /* Position awf-infoBox towards the middle while left-justified */
  .awf-infoBox {
    align-self: center; /* Ensures the box stays centered within the container */
  }

  /* Ensure labels in awf-descriptionBox are above the description */
  .awf-descriptionBox {
    flex-direction: column; /* Stack label above description on mobile */
  }
}

/* Optional: Enhance the card layout */
.awf-card {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content within the card */
 
}

/* Optional: Adjust card content padding for consistency */
.awf-cardContent {
  width: 100%;
 
  padding: 2rem 1rem;
}

/* Style for the breadcrumbs to align centrally */
.awf-breadcrumbs {
  width: 100%;
  max-width: 600px;
  margin: 0 auto 1.5rem auto;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Ensure the description box has proper spacing below the info box */
.awf-descriptionBox {
  margin-top: 1rem;
}

/* Other existing CSS continues... */

/* Center the awf-infoBox horizontally within the card and left-justify its content */
.AwF-AppointmentWorkFlow .awf-infoBox {
  text-align: left; /* Ensure text is left-aligned */
}

/* Additional mobile-specific adjustments */
@media (max-width: 768px) {
  .awf-infoBox,
  .awf-descriptionBox {
    max-width: 95%; /* Increase width for better readability */
    padding: 1rem; /* Adjust padding */
  }

  .awf-infoBox {
    align-self: center; /* Keep the box centered within the container */
    text-align: left; /* Left-justify the content */
  }
}

/* Ensure the card header stretches the full width */
.AwF-AppointmentWorkFlow .awf-cardHeader {
  width: 100%; /* Ensure full width */
  margin-left: 0; /* Remove any left margin */
  margin-right: 0; /* Remove any right margin */
  padding: 2rem 1rem; /* Adjust padding as needed */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Adjust breadcrumbs to align left */
.AwF-AppointmentWorkFlow .awf-breadcrumbs {
  justify-content: flex-start; /* Align breadcrumbs to the left */
  margin-left: 20px; /* Optional: add left margin for spacing */
  padding-left: 0; /* Remove any left padding */
}

/* Remove any conflicting generic styles for cardHeader */
.awf-cardHeader {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 2rem 1rem;
  box-sizing: border-box;
}

/* Adjust breadcrumbs globally if necessary */
.awf-breadcrumbs {
  justify-content: flex-start;
}

/* Make the status button larger for mobile */
.awf-statusButton {
  padding: 20px 40px; /* Further increase padding for mobile */
  font-size: 1.4rem; /* Further increase font size for mobile */
}

/*--------------------------------------------*/
/* Status Section */
/*--------------------------------------------*/

/* Parent container for status section */
.awf-statusSection {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers items horizontally */
  text-align: center;  /* Centers text within child elements */
  margin-top: 20px;    /* Adds spacing above the status section */
}

/* Status Heading */
.awf-statusSection h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem; /* Adds spacing below the heading */
  color: #333;
}

/* Container for the status buttons */
.awf-statusButtons {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  width: 100%;             /* Ensures the container spans the full width */
}

/* Status Button */
.awf-statusButtons .awf-statusButton {
  padding: 12px 24px;      /* Increased padding for larger size */
  font-size: 1.1rem;       /* Increased font size for better readability */
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

/* Hover Effect for Status Button */
.awf-statusButtons .awf-statusButton:hover:not(:disabled) {
  background-color: #0056b3;
  transform: translateY(-2px); /* Slight lift on hover */
}

/* Disabled State for Status Button */
.awf-statusButtons .awf-statusButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Responsive Adjustments for Mobile Devices */
@media (max-width: 768px) {
  .awf-statusButtons .awf-statusButton {
    padding: 20px 40px;    /* Further increase padding for touch devices */
    font-size: 1.4rem;     /* Further increase font size for readability */
  }
}

/* Container for the sidebar and content */
.awf-container {
  display: flex;
  min-height: 100vh;
}

/* Sidebar styles */
.pro-sidebar {
  position: fixed;
  height: 100%;
  z-index: 1000;
}

/* Content area adjustments */
.awf-content {
  flex: 1;
  margin-left: 240px; /* Width of the sidebar */
  padding: 20px;
  transition: margin-left 0.3s ease;
}

/* Adjustments for mobile view */
@media (max-width: 768px) {
  .awf-content {
    margin-left: 0;
  }
  .pro-sidebar {
    position: fixed;
    height: 100%;
    z-index: 1000;
  }
}

.pro-sidebar.collapsed ~ .awf-content {
  margin-left: 80px; /* Adjust based on the collapsed width */
}

@media (max-width: 768px) {
  .awf-content {
    margin-left: 0;
  }
}

.awf-toggleButton {
  position: fixed;
  bottom: 70px; /* Position it above the footer */
  right: 20px;  /* Position it 20px from the right */
  z-index: 1001; /* Ensure it's above the sidebar and overlay */
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%; /* Make it circular */
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s;
  width: 50px; /* Set width for circular button */
  height: 50px; /* Set height for circular button */
  display: flex;
  justify-content: center;
  align-items: center;
}

.awf-toggleButton:hover {
  background-color: #003d7a;
  transform: translateY(-2px); /* Slight lift on hover */
}

/* Sidebar container adjustments */
.awf-sidebarContainer {
  position: fixed;
  top: auto; /* Remove the default top positioning */
  bottom: 130px; /* Position it just above the toggle button */
  right: 0; /* Align to the right */
  height: 250px; /* Maintain the fixed height for the sidebar */
  width: 80px; /* Adjust width to fit icons */
  background-color: #0056b3; /* Set the background color to blue */
  box-shadow: -2px 0 5px rgba(0,0,0,0.1);
  z-index: 1000; /* Ensure it overlays other elements */
  transform: translateX(100%); /* Hide by default */
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center menu items vertically */
  align-items: center; /* Center icons horizontally */
}

/* Open state for the sidebar */
.awf-sidebarContainer.open {
  transform: translateX(0); /* Show the sidebar */
}

/* Center Menu Items Vertically within ProSidebar */
.pro-sidebar .menu {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center icons horizontally */
  gap: 20px; /* Add space between icons */
}

/* Adjust MenuItem Padding and Alignment */
.pro-sidebar .menu .menu-item {
  padding: 10px 0; /* Adjust padding for vertical spacing */
  text-align: center; /* Center text (icons) */
  color: white; /* Ensure icons are visible on blue background */
}

/* Ensure the footer is accounted for (If you have a footer, adjust accordingly) */
/* Example footer styling (if not already present) */
.awf-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px; /* Adjust height as needed */
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; /* Below the toggle button */
}

/* Adjust Content Spacing to Avoid Overlap with Footer */
.awf-content-spacing {
  margin-left: 2%;
  margin-right: 2%;
  min-height: calc(100vh - 267px - 50px); /* Subtract footer height */
  margin-top: 50px;
  padding-bottom: 70px; /* Add padding to prevent content from being hidden behind the toggle button */
}

/* Toggle Button - Move to Bottom */
.awf-toggleButton {
  position: fixed;
  bottom: 70px; /* Position it above the footer */
  right: 20px;  /* Position it 20px from the right */
  z-index: 1001; /* Ensure it's above the sidebar and overlay */
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%; /* Make it circular */
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s;
  width: 50px; /* Set width for circular button */
  height: 50px; /* Set height for circular button */
  display: flex;
  justify-content: center;
  align-items: center;
}

.awf-toggleButton:hover {
  background-color: #003d7a;
  transform: translateY(-2px); /* Slight lift on hover */
}

/* Overlay - Ensure it's behind the toggle button */
.awf-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 1000; /* Below the toggle button */
  display: none;
}

.awf-overlay.open {
  display: block;
}

/* Active Icon Color */
.pro-sidebar .menu .menu-item.active svg {
  color: #0056b3; /* Set active icon color */
}

/* Change the background color of the pro-sidebar-inner */
.pro-sidebar-inner {
  background-color: #0056b3; /* Set the background color to blue */
}

/* Add these styles to your CSS file */

.awf-thumbnail {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.awf-imagePreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.awf-deleteImageButton {
  margin-top: 5px;
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  cursor: pointer;
}

.awf-deleteImageButton:hover {
  background-color: #c82333;
}

/* Thumbnail Gallery */
.awf-thumbnailGallery {
  margin-bottom: 20px;
}

.awf-existingImagesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.awf-thumbnailItem {
  width: 100px;
  text-align: center;
}

.awf-thumbnailImage {
  width: 100%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.awf-noThumbnails {
  color: #666;
}

/* Selected Images in Modal */
.awf-selectedImagesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.awf-imagePreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.awf-thumbnail {
  width: 100px;
  height: auto;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.awf-deleteImageButton {
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.awf-deleteImageButton:hover {
  background-color: #c82333;
}

/* Existing Images Section */
.awf-existingImagesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Signature Canvas */
.awf-signatureCanvas {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.signature-canvas {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.awf-clearSignatureButton {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.awf-clearSignatureButton:hover {
  background-color: #c82333;
}

/* Signature Preview */
.awf-signaturePreview,
.awf-signaturePreviewModal {
  margin-top: 15px;
}

.awf-signatureImage {
  width: 300px;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Modal Signature Clear Button */
.awf-clearSignatureButtonModal {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.awf-clearSignatureButtonModal:hover {
  background-color: #c82333;
}

/* Hide Thumbnail Gallery Styles */
/* Ensure any leftover thumbnail styles are removed or hidden */

/* Existing styles continue... */

/* Ensure the signature canvas fits within the modal on all devices */

/* Container for signature capture modal */
.awf-signatureCapture {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* Add padding to prevent content from touching the modal edges */
  padding: 1rem;
  box-sizing: border-box;
}

/* Signature Canvas Styling */
.awf-signatureCapture canvas.signature-canvas {
  width: 100% !important; /* Ensure the canvas takes full width of its container */
  max-width: 500px; /* Set a maximum width for larger screens */
  height: auto !important; /* Allow height to adjust automatically */
  border: 1px solid #ccc; /* Optional: Add a border for better visibility */
  border-radius: 8px; /* Optional: Add rounded corners */
}

/* Modal Body Adjustments */
.awf-signatureModal .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

/* Responsive Adjustments for Mobile Devices */
@media (max-width: 768px) {
  /* Reduce padding on smaller screens */
  .awf-signatureCapture {
    padding: 0.5rem;
  }

  /* Adjust the maximum width for the canvas */
  .awf-signatureCapture canvas.signature-canvas {
    max-width: 90%; /* Allow more flexibility on smaller screens */
  }

  /* Ensure buttons fit within the modal */
  .awf-signatureModal .modal-footer {
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }

  .awf-signatureModal .modal-footer button {
    width: 100%;
    max-width: 300px;
  }
}

/* Optional: Ensure the modal does not exceed viewport width */
.awf-signatureModal .modal-dialog {
  max-width: 90%;
  width: auto;
}

/* Ensure images within the modal are responsive */
.awf-signaturePreviewModal img.awf-signatureImage {
  max-width: 100%;
  height: auto;
}

/* Adjust buttons within the signature modal */
.awf-signatureModal .modal-footer button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  margin: 0 0.5rem;
}

/* Ensure the signature modal is centered and scrollable if content overflows */
.awf-signatureModal .modal-content {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

/* Adjust the upload button to center the icon and set appropriate size */
.awf-uploadButton {
  background-color: #f0f0f0;
  border: none;
  padding: 10px;
  margin: 5px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.awf-uploadButton:hover {
  background-color: #e0e0e0;
}

.awf-uploadButton .awf-icon {
  font-size: 24px; /* Increase icon size if necessary */
}

/* Updated Styling for Image Handling Buttons */
.awf-uploadButton {
  background-color: #007bff; /* Primary Blue Color */
  border: none;
  padding: 10px 20px; /* Increased horizontal padding for rectangular shape */
  margin: 5px;
  border-radius: 4px; /* Slightly rounded corners for a modern look */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px; /* Ensures buttons have a minimum width */
  height: 50px; /* Maintains consistent height */
  transition: background-color 0.3s, transform 0.2s;
}

.awf-uploadButton:hover {
  background-color: #0056b3; /* Darker Blue on Hover */
  transform: translateY(-2px); /* Subtle lift effect on hover */
}

.awf-uploadButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Focus Ring for Accessibility */
}

.awf-uploadButton .awf-icon {
  font-size: 24px; /* Icon Size */
  color: #ffffff; /* White Icon Color for Contrast */
  display: flex;
  align-items: center;
  justify-content: center;
}

.awf-readOnly {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.awf-readOnly {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.awf-customerNotesBox {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 10px;
  min-height: 100px;
  max-height: 200px;
  overflow-y: auto;
  background-color: #f8f9fa;
}

/* Optional: Add scrollbar styling for better UI */
.awf-customerNotesBox::-webkit-scrollbar {
  width: 8px;
}

.awf-customerNotesBox::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.awf-customerNotesBox::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);
}

/* New Styles for Consistent Notes Sections */
.awf-notesSection {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Adds space between each form group */
}

.awf-notesFormGroup {
  display: flex;
  flex-direction: column;
}

/* Common Scrollable Box Style */
.awf-scrollableBox {
  width: 100%;
  max-width: 100%; /* Ensures it uses the available width */
  height: 150px; /* Set a fixed height */
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  overflow-y: auto; /* Enables vertical scrolling */
  background-color: #fff; /* Optional: Ensures background consistency */
}

/* Optional: Adjust the height for larger screens */
@media (min-width: 768px) {
  .awf-scrollableBox {
    height: 200px;
  }
}

/* Optional: Customize scrollbars for better aesthetics */
.awf-scrollableBox::-webkit-scrollbar {
  width: 8px;
}

.awf-scrollableBox::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.awf-scrollableBox::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Hamburger Icon */
.hamburger {
    position: relative;
    width: 30px;
    height: 3px;
    background-color: white;
    display: block;
    transition: all 0.3s ease;
    margin: 6px 0;
}

.hamburger::before,
.hamburger::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: white;
    transition: all 0.3s ease;
}

.hamburger::before {
    top: -10px;
}

.hamburger::after {
    top: 10px;
}

/* Transform to "X" */
.hamburger.open {
    background-color: transparent;
}

.hamburger.open::before {
    transform: rotate(45deg);
    top: 0;
}

.hamburger.open::after {
    transform: rotate(-45deg);
    top: 0;
}

.awf-noteButtons {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

/* Ensure buttons are displayed inline and adjust their size */
.awf-actionsSection {
  display: flex;
  justify-content: flex-start; /* Align buttons to the start */
  gap: 10px; /* Add space between buttons */
}

.awf-dispatchButton,
.awf-cancelButton {
  padding: 0.5rem 1rem; /* Adjust padding for smaller buttons */
  font-size: 0.9rem; /* Adjust font size for smaller buttons */
  width: auto; /* Allow buttons to size based on content */
  display: inline-block; /* Ensure buttons are inline */
  margin: 0; /* Remove any margin that might cause stacking */
}

/* Optional: Adjust the actions column width if needed */
.awf-actionsColumn {
  min-width: 150px; /* Adjust the minimum width as needed */
}

.awf-appointmentCard {
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.awf-appointmentCard:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.awf-signatureCapture {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.awf-signatureCapture canvas.signature-canvas {
  /* Remove width and height styles */
  /* width: 100% !important; */
  /* height: auto !important; */
  max-width: 500px; /* Set a maximum width if desired */
  width: 100%; /* Canvas will occupy 100% of its parent's width */
  border: 1px solid #ccc;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .awf-signatureCapture {
    padding: 0.5rem;
  }

  .awf-signatureCapture canvas.signature-canvas {
    max-width: 100%; /* Allow canvas to scale on smaller screens */
  }
}

.awf-clearSignatureButton {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.awf-clearSignatureButton:hover {
  background-color: #c82333;
}

.awf-blocks {
  font-size: 1.2em;
  letter-spacing: 2px; /* Adjust spacing between circles */
}

/* Adjust the blocks (pips) styling */
.awf-blocks {
  display: inline-block;
  white-space: nowrap;
}

.awf-dispatchButton,
.awf-cancelButton {
  margin-right: 8px; /* Ensure buttons have space between them */
}

.awf-table th,
.awf-table td {
  vertical-align: middle;
}

/* Adjust table headers and cells for better spacing */
.awf-table th,
.awf-table td {
  padding: 8px 12px; /* Increase padding for better readability */
  text-align: left; /* Left-align text */
  border-bottom: 1px solid #ddd; /* Add a subtle border for separation */
  white-space: nowrap; /* Prevent text from wrapping in narrow columns */
}

/* Allow specific columns to wrap text if needed */
.awf-table .wrap-text {
  white-space: normal; /* Allow text wrapping */
  word-wrap: break-word;
}

/* Example media query adjustment */
@media (max-width: 768px) {
  /* Adjust table margins for smaller screens */
  .awf-table {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }

  /* Adjust padding in table cells for smaller screens */
  .awf-table th,
  .awf-table td {
    padding: 6px 8px;
  }
}

.awf-blocks span {
  cursor: pointer;
}

.awf-formGroup {
  margin-bottom: 15px;
}

.awf-formGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.awf-formGroup input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

/* Style for On Hold appointments */
.awf-appointmentCard.on-hold {
  background-color: #fff3cd; /* Pastel yellow background */
}

.awf-addressLink {
  color: inherit; /* Use the same color as the surrounding text */
  text-decoration: none; /* Remove underline */
  cursor: pointer; /* Show pointer cursor on hover */
}

.awf-addressLink:hover {
  text-decoration: underline; /* Optionally add underline on hover */
}

/* Add styles for table cells to handle text overflow */
.awf-table {
  width: 100%;
  border-collapse: collapse;
}

.awf-table th,
.awf-table td {
  padding: 8px; /* Adjust padding as needed */
  text-align: left;
  border-bottom: 1px solid #ddd;
  /* Ensure that text does not wrap */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Specifically target the 'details' column for fixed width */
.awf-table th:nth-child(3),
.awf-table td:nth-child(3) {
  max-width: 300px; /* Adjust based on desired width */
}

/* Optional: Allow tooltip to show full text on hover */
.awf-table td.title-ellipsis {
  position: relative;
}

.awf-table td.title-ellipsis:hover::after {
  content: attr(title);
  position: absolute;
  left: 0;
  top: 100%;
  white-space: pre-wrap;
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  z-index: 10;
  width: max-content;
  max-width: 400px;
}

/* Specifically target the 'customerName' column for truncation */
.awf-table th:nth-child(2),
.awf-table td:nth-child(2) {
  max-width: 200px; /* Adjust based on desired width */
}

/* Optional: Allow tooltip to show full text on hover for 'customerName' */
.awf-table td.customer-name-ellipsis {
  position: relative;
}

.awf-table td.customer-name-ellipsis:hover::after {
  content: attr(title);
  position: absolute;
  left: 0;
  top: 100%;
  white-space: pre-wrap;
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  z-index: 10;
  width: max-content;
  max-width: 400px;
}